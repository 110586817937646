/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../../core/Handles.js";
import "../../core/has.js";
import t from "../../core/Logger.js";
import { removeMaybe as s } from "../../core/maybe.js";
import r from "../../core/PerformanceSampler.js";
import i from "../../core/PooledArray.js";
import { isAborted as a, createAbortError as n, when as _ } from "../../core/promiseUtils.js";
import { watch as h, initial as u, when as o } from "../../core/reactiveUtils.js";
import { signal as d } from "../../core/signal.js";
import { Milliseconds as E } from "../../core/time.js";
import { PromiseQueue as l } from "../../layers/support/PromiseQueue.js";
import T from "./debugFlags.js";
import { RenderState as m } from "./RenderState.js";
import { Yield as c } from "./Yield.js";
function I() {
  return new U.Scheduler();
}
var g;
!function (e) {
  e.RESOURCE_CONTROLLER_IMMEDIATE = "immediate", e.RESOURCE_CONTROLLER = "schedule", e.SLIDE = "slide", e.STREAM_DATA_LOADER = "stream loader", e.ELEVATION_QUERY = "elevation query", e.TERRAIN_SURFACE = "terrain", e.SURFACE_GEOMETRY_UPDATES = "surface geometry updates", e.LOD_RENDERER = "LoD renderer", e.GRAPHICS_CORE = "Graphics3D", e.I3S_CONTROLLER = "I3S", e.POINT_CLOUD_LAYER = "point cloud", e.FEATURE_TILE_FETCHER = "feature fetcher", e.OVERLAY = "overlay", e.STAGE = "stage", e.GRAPHICS_DECONFLICTOR = "graphics deconflictor", e.FILTER_VISIBILITY = "Graphics3D filter visibility", e.SCALE_VISIBILITY = "Graphics3D scale visibility", e.FRUSTUM_VISIBILITY = "Graphics3D frustum visibility", e.POINT_OF_INTEREST_FREQUENT = "POI frequent", e.POINT_OF_INTEREST_INFREQUENT = "POI infrequent", e.LABELER = "labeler", e.FEATURE_QUERY_ENGINE = "feature query", e.FEATURE_TILE_TREE = "feature tile tree", e.FEATURE_TILE_TREE_ACTIVE = "fast feature tile tree", e.ELEVATION_ALIGNMENT = "elevation alignment", e.ELEVATION_ALIGNMENT_SCENE = "elevation alignment scene", e.TEXT_TEXTURE_ATLAS = "text texture atlas", e.TEXTURE_UNLOAD = "texture unload", e.LINE_OF_SIGHT_TOOL = "line of sight tool", e.LINE_OF_SIGHT_TOOL_INTERACTIVE = "interactive line of sight tool", e.ELEVATION_PROFILE = "elevation profile", e.SNAPPING = "snapping", e.SHADOW_ACCUMULATOR = "shadow accumulator", e.CLOUDS_GENERATOR = "clouds generator", e.MAPVIEW_FETCH_QUEUE = "mapview fetch queue", e.MAPVIEW_LAYERVIEW_UPDATE = "mapview layerview update", e.MAPVIEW_VECTOR_TILE_PARSING_QUEUE = "mapview vector tile parsing queue", e[e.NONE = 0] = "NONE", e[e.TEST_PRIO = 1] = "TEST_PRIO";
}(g || (g = {}));
const R = 0,
  p = new Map([[g.RESOURCE_CONTROLLER_IMMEDIATE, R], [g.RESOURCE_CONTROLLER, 4], [g.SLIDE, R], [g.STREAM_DATA_LOADER, R], [g.ELEVATION_QUERY, R], [g.TERRAIN_SURFACE, 1], [g.SURFACE_GEOMETRY_UPDATES, 1], [g.LOD_RENDERER, 2], [g.GRAPHICS_CORE, 2], [g.I3S_CONTROLLER, 2], [g.POINT_CLOUD_LAYER, 2], [g.FEATURE_TILE_FETCHER, 2], [g.CLOUDS_GENERATOR, 2], [g.OVERLAY, 4], [g.STAGE, 4], [g.GRAPHICS_DECONFLICTOR, 4], [g.FILTER_VISIBILITY, 4], [g.SCALE_VISIBILITY, 4], [g.FRUSTUM_VISIBILITY, 4], [g.POINT_OF_INTEREST_FREQUENT, 6], [g.POINT_OF_INTEREST_INFREQUENT, 30], [g.LABELER, 8], [g.FEATURE_QUERY_ENGINE, 8], [g.FEATURE_TILE_TREE, 16], [g.FEATURE_TILE_TREE_ACTIVE, R], [g.ELEVATION_ALIGNMENT, 12], [g.ELEVATION_ALIGNMENT_SCENE, 14], [g.TEXT_TEXTURE_ATLAS, 12], [g.TEXTURE_UNLOAD, 12], [g.LINE_OF_SIGHT_TOOL, 16], [g.LINE_OF_SIGHT_TOOL_INTERACTIVE, R], [g.SNAPPING, R], [g.SHADOW_ACCUMULATOR, 30], [g.MAPVIEW_FETCH_QUEUE, R], [g.MAPVIEW_LAYERVIEW_UPDATE, 2], [g.MAPVIEW_VECTOR_TILE_PARSING_QUEUE, R]]);
function A(e) {
  return p.has(e) ? p.get(e) : "number" == typeof e ? e : 1;
}
const f = E(6.5),
  L = E(1),
  N = E(30),
  O = E(1e3 / 30),
  S = E(100),
  b = .9;
var U, k;
!function (a) {
  class n {
    get updating() {
      return this._updating.value;
    }
    _updatingChanged() {
      this._updating.value = this._tasks.some(e => e.needsUpdate);
    }
    constructor() {
      this._updating = d(!0), this._microTaskQueued = !1, this._frameNumber = 0, this.performanceInfo = {
        total: new r("total"),
        tasks: new Map()
      }, this._frameTaskTimes = new Map(), this._budget = new I(), this._state = m.INTERACTING, this._tasks = new i(), this._runQueue = new i(), this._load = 0, this._idleStateCallbacks = new i(), this._idleUpdatesStartFired = !1, this._forceTask = !1, this._debug = !1, this._debugHandle = h(() => T.SCHEDULER_LOG_SLOW_TASKS, e => this._debug = e, u);
      for (const e of Object.keys(g)) this.performanceInfo.tasks.set(g[e], new r(g[e]));
    }
    destroy() {
      this._tasks.toArray().forEach(e => e.remove()), this._tasks.clear(), s(this._debugHandle), this._microTaskQueued = !1, this._updatingChanged();
    }
    taskRunningChanged(e) {
      this._updatingChanged(), e && this._budget.remaining > 0 && !this._microTaskQueued && (this._microTaskQueued = !0, queueMicrotask(() => {
        this._microTaskQueued && (this._microTaskQueued = !1, this._budget.remaining > 0 && this._schedule() && this.frame());
      }));
    }
    registerTask(e, t) {
      const s = new _(this, e, t);
      return this._tasks.push(s), this._updatingChanged(), this.performanceInfo.tasks.has(e) || this.performanceInfo.tasks.set(e, new r(e)), s;
    }
    registerIdleStateCallbacks(e, t) {
      const s = {
        idleBegin: e,
        idleEnd: t
      };
      this._idleStateCallbacks.push(s), this.state === m.IDLE && this._idleUpdatesStartFired && s.idleBegin();
      const r = this;
      return {
        remove: () => this._removeIdleStateCallbacks(s),
        set idleBegin(e) {
          r._idleUpdatesStartFired && (s.idleEnd(), r._state === m.IDLE && e()), s.idleBegin = e;
        },
        set idleEnd(e) {
          s.idleEnd = e;
        }
      };
    }
    get load() {
      return this._load;
    }
    set state(e) {
      this._state !== e && (this._state = e, this.state !== m.IDLE && this._idleUpdatesStartFired && (this._idleUpdatesStartFired = !1, this._idleStateCallbacks.forAll(e => e.idleEnd())));
    }
    get state() {
      return this._state;
    }
    updateBudget(e) {
      this._test && (this._test.usedBudget = 0), ++this._frameNumber;
      let t = f,
        s = e.frameDuration,
        r = L;
      switch (this.state) {
        case m.IDLE:
          t = E(0), s = E(Math.max(S, e.frameDuration)), r = N;
          break;
        case m.INTERACTING:
          s = E(Math.max(O, e.frameDuration));
        case m.ANIMATING:
      }
      return s = E(s - e.elapsedFrameTime - t), this.state !== m.IDLE && s < L && !this._forceTask ? (this._forceTask = !0, !1) : (s = E(Math.max(s, r)), this._budget.reset(s, this.state), this._updateLoad(), this._schedule());
    }
    frame() {
      switch (this._forceTask = !1, this._microTaskQueued = !1, this.state) {
        case m.IDLE:
          this._idleUpdatesStartFired || (this._idleUpdatesStartFired = !0, this._idleStateCallbacks.forAll(e => e.idleBegin())), this._runIdle();
          break;
        case m.INTERACTING:
          this._runInteracting();
          break;
        default:
          this._runAnimating();
      }
      this._test && (this._test.usedBudget = this._budget.elapsed);
    }
    stopFrame() {
      this._budget.reset(E(0), this._state), this._budget.madeProgress();
    }
    _removeIdleStateCallbacks(e) {
      this._idleUpdatesStartFired && e.idleEnd(), this._idleStateCallbacks.removeUnordered(e);
    }
    removeTask(e) {
      this._tasks.removeUnordered(e), this._runQueue.removeUnordered(e), this._updatingChanged();
    }
    _updateTask(e) {
      this._tasks.forAll(t => {
        t.name === e && t.setPriority(e);
      });
    }
    _getState(e) {
      if (this._runQueue.some(t => t.name === e)) return k.SCHEDULED;
      let t = k.IDLE;
      return this._tasks.forAll(s => {
        s.name === e && s.needsUpdate && (s.schedulePriority <= 1 ? t = k.READY : t !== k.READY && (t = k.WAITING));
      }), t;
    }
    _getRuntime(e) {
      let t = 0;
      return this._tasks.forAll(s => {
        s.name === e && (t += s.runtime);
      }), t;
    }
    _resetRuntimes() {
      this._tasks.forAll(e => e.runtime = 0);
    }
    _getRunning() {
      const e = new Map();
      if (this._tasks.forAll(t => {
        t.needsUpdate && e.set(t.name, (e.get(t.name) || 0) + 1);
      }), 0 === e.size) return null;
      let t = "";
      return e.forEach((e, s) => {
        t += e > 1 ? ` ${e}x ${s}` : ` ${s}`;
      }), t;
    }
    _runIdle() {
      this._run();
    }
    _runInteracting() {
      this._run();
    }
    _runAnimating() {
      this._run();
    }
    _updateLoad() {
      const e = this._tasks.reduce((e, t) => t.needsUpdate ? ++e : e, 0);
      this._load = this._load * b + e * (1 - b);
    }
    _schedule() {
      for (this._runQueue.filterInPlace(e => !!e.needsUpdate || (e.schedulePriority = e.basePriority, !1)), this._tasks.forAll(e => {
        e.basePriority === R && e.needsUpdate && !this._runQueue.includes(e) && e.blockFrame !== this._frameNumber && this._runQueue.unshift(e);
      }); 0 === this._runQueue.length;) {
        let e = !1,
          t = 0;
        if (this._tasks.forAll(s => {
          if (s.needsUpdate && 0 !== s.schedulePriority && s.basePriority !== R && s.blockFrame !== this._frameNumber) if (e = !0, t = Math.max(t, s.basePriority), 1 === s.schedulePriority) s.schedulePriority = 0, this._runQueue.push(s);else --s.schedulePriority;
        }), !e) return this._updatingChanged(), !1;
      }
      return this._updatingChanged(), !0;
    }
    _run() {
      const e = this._budget.now();
      this._startFrameTaskTimes();
      do {
        for (; this._runQueue.length > 0;) {
          const r = this._budget.now(),
            i = this._runQueue.pop();
          this._budget.resetProgress();
          try {
            i.task.runTask(this._budget) === c && (i.blockFrame = this._frameNumber);
          } catch (s) {
            t.getLogger("esri.views.support.Scheduler").error(`Exception in task "${i.name}"`, s), i.blockFrame = this._frameNumber;
          }
          !this._budget.hasProgressed && i.blockFrame !== this._frameNumber && i.needsUpdate && (i.name, g.I3S_CONTROLLER, i.blockFrame = this._frameNumber), i.schedulePriority = i.basePriority;
          const a = this._budget.now() - r;
          if (i.runtime += a, this._frameTaskTimes.set(i.priority, this._frameTaskTimes.get(i.priority) + a), this._budget.remaining <= 0) return this._updatingChanged(), void this._recordFrameTaskTimes(this._budget.now() - e);
        }
      } while (this._schedule());
      this._updatingChanged(), this._recordFrameTaskTimes(this._budget.now() - e);
    }
    _startFrameTaskTimes() {
      for (const e of Object.keys(g)) this._frameTaskTimes.set(g[e], 0);
    }
    _recordFrameTaskTimes(e) {
      this._frameTaskTimes.forEach((e, t) => this.performanceInfo.tasks.get(t).push(e)), this.performanceInfo.total.push(e);
    }
    get test() {
      return this._test;
    }
  }
  a.Scheduler = n;
  class _ {
    get task() {
      return this._task.value;
    }
    get updating() {
      return this._queue.running;
    }
    constructor(t, s, r) {
      this._scheduler = t, this.name = s, this.blockFrame = 0, this.runtime = 0, this._queue = new l(), this._handles = new e(), this._basePriority = A(s), this.schedulePriority = this._basePriority, this._task = d(null != r ? r : this._queue), this._handles.add(o(() => this.task.running, e => t.taskRunningChanged(e)));
    }
    remove() {
      this.processQueue(C), this._scheduler.removeTask(this), this.schedule = F.schedule, this.reschedule = F.reschedule, this._handles.destroy();
    }
    get basePriority() {
      return this._basePriority;
    }
    setPriority(e) {
      if (this.name === e) return;
      this.name = e;
      const t = A(e);
      this._basePriority !== R && 0 === this.schedulePriority || (this.schedulePriority = t), this._basePriority = t;
    }
    get priority() {
      return this.name;
    }
    set priority(e) {
      this.setPriority(e);
    }
    get needsUpdate() {
      return this.updating || this.task.running;
    }
    schedule(e, t, s) {
      return this._queue.push(e, t, s);
    }
    reschedule(e, t, s) {
      return this._queue.unshift(e, t, s);
    }
    processQueue(e) {
      return this._queue.runTask(e);
    }
  }
  class I {
    constructor() {
      this._begin = "undefined" != typeof performance ? performance.now() : 0, this._budget = 0, this._state = m.IDLE, this._done = !1, this._progressed = !1, this._enabled = !0;
    }
    run(e) {
      return !this.done && (!0 === e() && this.madeProgress(), !0);
    }
    get done() {
      return this._done;
    }
    get budget() {
      return this._budget;
    }
    madeProgress() {
      return this._progressed = !0, this._done = this.elapsed >= this._budget && this._enabled, this._done;
    }
    get state() {
      return this._state;
    }
    get enabled() {
      return this._enabled;
    }
    set enabled(e) {
      this._enabled = e;
    }
    reset(e, t) {
      this._begin = this.now(), this._budget = e, this._state = t, this.resetProgress();
    }
    get remaining() {
      return Math.max(this._budget - this.elapsed, 0);
    }
    now() {
      return performance.now();
    }
    get elapsed() {
      return this.now() - this._begin;
    }
    resetProgress() {
      this._progressed = !1, this._done = !1;
    }
    get hasProgressed() {
      return this._progressed;
    }
  }
  a.Budget = I;
}(U || (U = {})), function (e) {
  e.SCHEDULED = "s", e.READY = "r", e.WAITING = "w", e.IDLE = "i";
}(k || (k = {}));
const C = (() => {
  const e = new U.Budget();
  return e.enabled = !1, e;
})();
class P {
  remove() {}
  processQueue() {}
  schedule(e, t, s) {
    try {
      if (a(t)) {
        const e = n();
        return s ? Promise.resolve(s(e)) : Promise.reject(e);
      }
      return _(e(C));
    } catch (r) {
      return Promise.reject(r);
    }
  }
  reschedule(e, t, s) {
    return this.schedule(e, t, s);
  }
}
const F = new P();
export { F as ImmediateTask, g as TaskPriority, k as TaskState, A as getTaskPriority, I as newScheduler, C as noBudget, p as taskPriorities };