/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { isAborted as s, createAbortError as t, isPromiseLike as e } from "../../core/promiseUtils.js";
import { signal as r } from "../../core/signal.js";
import { Yield as n } from "../../views/support/Yield.js";
class i {
  constructor() {
    this._tasks = new Array(), this._runningTasks = r(0);
  }
  get length() {
    return this._tasks.length;
  }
  get running() {
    return this._runningTasks.value > 0;
  }
  destroy() {
    this.cancelAll();
  }
  runTask(s) {
    if (0 === this.length) return n;
    for (; !s.done && this._process(s);) s.madeProgress();
  }
  push(s, t, e) {
    return ++this._runningTasks.value, new Promise((r, n) => this._tasks.push(new a(r, n, s, t, e))).finally(() => --this._runningTasks.value);
  }
  unshift(s, t, e) {
    return ++this._runningTasks.value, new Promise((r, n) => this._tasks.unshift(new a(r, n, s, t, e))).finally(() => --this._runningTasks.value);
  }
  _process(r) {
    if (0 === this._tasks.length) return !1;
    const n = this._tasks.shift();
    try {
      const i = s(n.signal);
      if (i && !n.abortCallback) n.reject(t());else {
        const s = i ? n.abortCallback?.(t()) : n.callback(r);
        e(s) ? s.then(n.resolve, n.reject) : n.resolve(s);
      }
    } catch (i) {
      n.reject(i);
    }
    return !0;
  }
  cancelAll() {
    const s = t();
    for (const t of this._tasks) if (t.abortCallback) {
      const e = t.abortCallback(s);
      t.resolve(e);
    } else t.reject(s);
    this._tasks.length = 0;
  }
}
class a {
  constructor(s, t, e, r = void 0, n = void 0) {
    this.resolve = s, this.reject = t, this.callback = e, this.signal = r, this.abortCallback = n;
  }
}
export { i as PromiseQueue };